import React, { useEffect, useContext, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden, Typography,
  SvgIcon, Menu, MenuItem, Button, Divider
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "src/context/User";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
  },
  toolbar: {
    background: "#FFFFFF !important",
    height: "65px",
    padding: "10px 18px 10px 8px",

    "& svg": {
      fontSize: "25px !important",
    },
  },
  newnavbar: {
    marginTop: "27px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    width: "100%",
    paddingLeft: "300px",
    '@media screen and (min-width: 0px)and (max-width: 1279px)': {
      paddingLeft: "0px"
    }
  },
  rightsidebar: {
    display: "flex",
    justifyContent: "space-between",
    alignItem: "center",
    gap: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      gap: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "10px",
    },
  },
  centerAlign: {
    display: "flex",
    alignItems: "center"
  },
  connectgitlabbtn: {
    background: "#1756FF",
    borderRadius: "12px",
    color: "white",
    height: "45px",
    width: "140px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      height: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      height: "25px",
    },
  },
  nameText: {
    color: "#2B2B2B",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "28.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "23px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      lineHeight: "23px",
    },
  },
  welcomeText: {
    color: "#5B5B5B",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "28.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
      lineHeight: "11px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
      lineHeight: "11px",
    },
  },
  logo: {
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      width: 105
    },
    [theme.breakpoints.down("xs")]: {
      width: 70
    },
  },
  dashIcon: {
    [theme.breakpoints.down("sm")]: {
      width: 16
    },
    [theme.breakpoints.down("xs")]: {
      width: 16
    },
  },
  dashIconActive: {
    [theme.breakpoints.down("sm")]: {
      width: 16
    },
    [theme.breakpoints.down("xs")]: {
      width: 16
    },
  },
  profilePic: {
    color: "black",
    cursor: "pointer",
    borderRadius: "10%",
    width: 48, height: 48,
    [theme.breakpoints.down("sm")]: {
      width: 28,
      height: 28
    },
    [theme.breakpoints.down("xs")]: {
      width: 25,
      height: 24
    },
  },
  menuitemsall: {
    color: "#2B2B2B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  menuitemsallActive: {
    color: "white",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    background: "#1756FF"
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [menuItemClick, setMenuItemClick] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth)
  });
  const handleClose = () => {
    setOpen(false);
  }
  const confirmationLogouthandler = () => {
    history.push("/");
    window.localStorage.removeItem("token");
    toast.success("Successfully logout.")

  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    user.getProfile();
  }, [])
  const profileData = user.profile;

  const handleMenuClick = (menuItem) => {
    setMenuItemClick(menuItem);

  };



  return (
    <>
      <AppBar
        elevation={0}
        className={clsx(classes.root, className)}
        color="inherit"
        {...rest}
      >
        <Toolbar className={classes.toolbar}>
          <nav className={classes.newnavbar}>
            <div style={{ display: "flex", gap: "15px" }}>
              {width < 1280 && <img src="/images/logo.svg" className={classes.logo} />}
              <div className={classes.leftSidebar}>
                <Typography className={classes.nameText}>Hi, <span style={{ color: "#1756FF", textTransform: "capitalize" }}>
                  {user.profile.firstname
                    ? user.profile.firstname.length > 6
                      ? user.profile.firstname.slice(0, 6) + "..."
                      : user.profile.firstname
                    : "user"}
                </span>
                  {/* <span style={{color:"#1756FF",textTransform: "capitalize",}}> {user.profile.firstname ? user.profile.firstname : "user"}</span> */}
                </Typography>
                <Typography className={classes.welcomeText}>Welcome to WeCode</Typography>
              </div>
            </div>
            <div className={classes.rightsidebar}>
              <div className={classes.centerAlign}>
                {/* {location.pathname == "/dashboard" && <img src="images/dashboardActive.svg" className={classes.dashIcon} />} */}
                {location.pathname == "/dashboard" && <img src="images/dashboard.svg" className={classes.dashIcon} />}
                {location.pathname != "/dashboard" && <img src="images/dashboard.svg" onClick={() => history.push("/dashboard")} className={classes.dashIconActive} style={{ cursor: "pointer" }} />}
              </div>
              <div className={classes.centerAlign}>
                < Button
                  variant="outlined"
                  className={classes.connectgitlabbtn}
                  onClick={() => history.push("/upload-file")}
                >
                  Upload
                </Button>
              </div>
              <div className={classes.centerAlign}>
                {!profileData.image && <img src="/images/squareAvtarimage.jpeg" className={classes.profilePic}
                  onClick={handleClick}
                />}
                {profileData.image && <img src={`https://v3py-expertchatbot.mobiloitte.io/${profileData.image}`} className={classes.profilePic}
                  onClick={handleClick}
                />}
                {/* <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem className={location.pathname === menuItemClick ? classes.menuitemsallActive : classes.menuitemsall} onClick={() => { handleMenuClick("/profile"); handleMenuClose(); history.push("/profile"); }}>Account settings</MenuItem>
                  <Divider />
                  <MenuItem className={location.pathname === menuItemClick ? classes.menuitemsallActive : classes.menuitemsall} onClick={() => { handleMenuClick("/change-password"); handleMenuClose(); history.push("/change-password"); }}>Change password</MenuItem>
                  <Divider />
                  <MenuItem className={location.pathname === menuItemClick ? classes.menuitemsallActive : classes.menuitemsall} onClick={() => { handleMenuClick("/change-gitlab-key"); handleMenuClose(); history.push("/change-gitlab-key"); }}>Change access token</MenuItem>
                  <Divider />
                  <MenuItem className={location.pathname === menuItemClick ? classes.menuitemsallActive : classes.menuitemsall} onClick={() => { handleMenuClose(); history.push("/openAIkeys"); }}>Open AI Keys</MenuItem>
                  <Divider />
                  <MenuItem
                    className={location.pathname === menuItemClick ? classes.menuitemsallActive : classes.menuitemsall}
                    onClick={() => {
                      handleMenuClick("/opertaion-List");
                      handleMenuClose();
                      history.push("/opertaion-List");
                    }}
                  >
                    Upload Operations
                  </MenuItem>
                  <Divider />
                  <MenuItem className={location.pathname === menuItemClick ? classes.menuitemsallActive : classes.menuitemsall} onClick={() => { handleMenuClick("/GitOperationView"); handleMenuClose(); history.push("/GitOperationView"); }}>Gitlab Operations</MenuItem>
                  <Divider />
                  <MenuItem className={classes.menuitemsall} onClick={() => { handleMenuClose(); setOpen(true); }}>Logout</MenuItem>
                </Menu> */}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    className={classes.menuitemsall}
                    onClick={() => {
                      handleMenuClose();
                      history.push("/profile");
                    }}
                  >
                    Account settings
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className={classes.menuitemsall}
                    onClick={() => {
                      handleMenuClose();
                      history.push("/change-password");
                    }}
                  >
                    Change password
                  </MenuItem>
                  <Divider />
                  <MenuItem className={classes.menuitemsall} onClick={() => { handleMenuClose(); history.push("/change-gitlab-key"); }}>Change access token</MenuItem>
                  <Divider />
                  <MenuItem className={classes.menuitemsall} onClick={() => { handleMenuClose(); history.push("/openAIkeys"); }}>Open AI Keys</MenuItem>
                  <Divider />
                  <MenuItem
                    className={classes.menuitemsall}
                    onClick={() => {
                      handleMenuClose();
                      history.push("/opertaion-List");
                    }}
                  >
                    Upload Operations
                  </MenuItem>
                  <Divider />
                  <MenuItem className={classes.menuitemsall} onClick={() => { handleMenuClose(); history.push("/GitOperationView"); }}>Gitlab Operations</MenuItem>
                  <Divider />
                  <MenuItem
                    className={classes.menuitemsall}
                    onClick={() => {
                      handleMenuClose();
                      setOpen(true);
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
              <Hidden lgUp>
                <IconButton
                  color="#FF2626"
                  onClick={onMobileNavOpen}
                >
                  <SvgIcon fontSize="small">
                    <MenuIcon style={{ color: "black" }} />
                  </SvgIcon>
                </IconButton>
              </Hidden>
            </div>
          </nav>
        </Toolbar>
      </AppBar>
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Are you sure you want to Logout ?"}
          confirmationHandler={confirmationLogouthandler}
        />
      )}
    </>
  );
};
TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;

import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import NavItem from "./NavItem";
import { UserContext } from "src/context/User";
import moment from 'moment';
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}

const useStyles = makeStyles((theme) => ({
  outerRepo: {
    background: "#F1F1F2",
    padding: "12px 16px 9px 16px",
    borderRadius: "6px",
    cursor: "pointer"
  },
  outerReponot: {
    padding: "10px 16px 0px 16px",
    cursor: "pointer"
  },
  subreponame: {
    background: "#FFFFFF",
    borderRadius: "10px",
    padding: "10px 15px 6px 15px",
  },
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.taf,
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  desktopDrawer: {
    width: "100%",
    maxWidth: 278,
    top: 0,
    height: "100%",
    background: "#FFFFFF",
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: `${theme.palette.text.primary} !important`,
  },
  Terms: {
    color: theme.palette.text.primary,
    // padding: "17px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    "& .MuiButton-label": {
      padding: "10px",
    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "#fff !important",
        background: "#3A96DD",
        padding: "10px",
        borderRadius: "9px",
        fontWeight: theme.typography.fontWeightRegular,
        "& $title": {
          fontWeight: theme.typography.fontWeightMedium,
        },
        "& $icon": {
          color: "#fff !important",
          // color: "00e0b0",
        },
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  srollBoxNav: {
    marginTop: "10px",
  },
  inputbranch: {
    "& .MuiInputBase-input": {
      background: "#F1F1F2",
      borderRadius: "6px",
      paddingLeft: "20%",
      paddingRight: "5%",
      height: "30px",
      "&::placeholder": {
        paddingLeft: "5%",
        color: "#7E8299 !important",
        fontSize: "14px",
        paddingTop: "15px",
        fontWeight: "500",
      },
    },
  },
  searchicon: {
    position: "absolute",
    left: "14px",
    top: "18px",
    height: "23px",
    width: "23px",
    cursor: "pointer",
  },
  projectheading: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "-0.01em",
    color: "#1E1E1E",
    paddingBottom: "20px",
  },
  reponame: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.6",
    color: "#1E1E1E",
    // letterSpacing: 0.00938em,
  },
  subbranchbox: {
    display: "flex",
    cursor: "pointer",
    gap: "10px",

  },
  repoBox: {
    display: "flex",
    gap: "1rem",
    paddingBottom: "20px",
    alignItems: "center",
  },
  repoSubBox: {
    display: "flex",
    alignItems: "center",
  },
  branchName: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
  },
  pathCss: {
    color: "#2B2B2B",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "16px",

    "& .truncate-text": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  language: {
    color: "var(--gray-gray-500, #A1A5B7)",

    fontSize: "13px",

    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "14px",
  },
  Gboxsidebar: {
    borderRadius: "5px",
    background: "#1756FF",
    width: "100%",
    maxWidth: "38px",
    height: "38px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  Gtyposide: {
    color: "#FFF",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: '16px',
    fontFamily: "Inter",
    fontStyle: "normal",
  },
  Gtyposideupdaredvalue: {
    color: "#black",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: '13px',
    fontFamily: "Inter",
    fontStyle: "normal",
  },
  highlighted: {
    color: "#1756FF", // You can set any other color you prefer
  },
  navboxrefa: {
    display: "flex", gap: "20px", marginLeft: "2rem", marginBottom: "20px"
  },
  logo: {
    paddingTop: "20px", cursor: "pointer", width: "106px",
  },
  rightleftbtn: {
    display: "flex", marginTop: "10px", justifyContent: "end", alignItems: "center"
  },
  noprojectbox: {
    border: "1px dashed #000000",
    borderRadius: "10px",
    padding: "15px",
    width: "100%",
    maxWidth: "155px",
    marginLeft: "19px",
  },
}));

const NavBar = ({ onMobileClose, openMobile, ...rest }) => {
  const classes = useStyles();
  const [subvalue, setSubvalue] = useState(null);
  const location = useLocation();
  const user = useContext(UserContext);
  const repoList = user.repoList;
  const DataallRefact = user.DataallRefact;
  const datacountrefact = user.datacountrefact;
  const history = useHistory();
  const loading = user.loading;
  const [currentProjectId, setCurrentProjectId] = useState("")
  const [selectedItem, setSelectedItem] = useState(null);
  const [loader, setLoader] = useState("");
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [current_page, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);


  useEffect(() => {
    setCurrentPage(datacountrefact.current_page);
    setTotalPages(datacountrefact.total_pages);
  }, [datacountrefact]);

  // Event handler for next button
  const handleNextPage = () => {
    if (current_page < totalPages) {
      const CurrentNextPage = current_page + 1;
      user.getUploadRefacor(CurrentNextPage);
      setCurrentPage(CurrentNextPage);
    }
  };

  // Event handler for previous button
  const handlePreviousPage = () => {
    if (current_page > 1) {
      const CurrentPreviousPage = current_page - 1;
      setCurrentPage(CurrentPreviousPage);
      user.getUploadRefacor(CurrentPreviousPage);

    }
  };

  useEffect(() => {
    const startIndex = (current_page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const newData = DataallRefact.slice(startIndex, endIndex);
    setData(newData);
  }, [current_page, DataallRefact]);


  const handleClick = (name) => {
    setSelectedItem(name);
    user.getBranchList(currentProjectId, name);
    user.getRepoName(name);
    history.push("/dashboard");
  };

  const FullScreenLoader = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999, // Ensure it's on top
    }}>
      <CircularProgress />
    </div>
  );

  const locationData = location;
  const handleTypeSelection = (type) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('type', type);
    history.replace({
      search: queryParams.toString(),
      state: location.state
    });
  };

  const [updatedAt, setUpdatedAt] = useState(null);

  let formattedDate;

  if (updatedAt) {
    const now = moment();
    const updatedTime = moment(updatedAt);
    const diffInDays = now.diff(updatedTime, 'days');

    if (diffInDays === 0) {
      formattedDate = updatedTime.fromNow();
    } else if (diffInDays === 1) {
      formattedDate = '1 day ago';
    } else {
      formattedDate = updatedTime.format('MMMM D, YYYY'); // Display date if more than 1 day ago
    }
  }
  // calling the repo list r project list api
  useEffect(() => {
    user.getRepoList()
    if (location.pathname === "/allsave-content") {
      user.getUploadRefacor(DataallRefact); // Fetch data for the current page
    }
  }, [])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location?.pathname]);
  const desiredRoutes = ["/allsave-content"];
  const currentRoute = location.pathname;

  const pageTitle =
    desiredRoutes.includes(currentRoute)
      ? currentRoute === "/test-case"
        ? "Test Cases"
        : currentRoute === "/code-refactor"
          ? "Refacatorized codes"
          : currentRoute === "/document"
            ? "Documentation"
            : currentRoute === "/allsave-content"
              ? "Refactorized Code"
              : "Refactorized"
      : "Project";

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className="srollBoxNav"
      style={{ overflowY: "auto" }}
    >
      <Box
        mb={-1}
        pt={3}
        display="flex"
        justifyContent="center"
        style={{ padding: "25px 30px 5px 30px" }}
      >
        <Logo alt="Logo" className={classes.logo} />
      </Box>

      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box my={3}>
          {loading && location.pathname !== "/profile" && location.pathname !== "/change-password" && location.pathname !== "/change-gitlab-key" ? <FullScreenLoader /> :
            <Box pl={2} pr={2}>
              <Box pt={2} pb={2} pl={2}>
                <Typography className={classes.projectheading}>
                  {pageTitle}
                </Typography>
              </Box>

              {(!desiredRoutes.includes(location.pathname) && repoList && repoList.length > 0) ? (
                repoList.map((value, index) => {
                  const updatedTime = moment(value.updated_at);
                  const timeAgo = updatedTime.fromNow();
                  return (
                    <>
                      <Box
                        className={
                          subvalue == index
                            ? classes.outerRepo
                            : classes.outerReponot
                        }
                      >
                        <Box className={classes.repoBox}>
                          <Box className={classes.Gboxsidebar}>

                            <Typography className={classes.Gtyposide}>
                              {value.name_with_namespace ? value.name_with_namespace[0].toUpperCase() : ""}
                            </Typography>
                          </Box>
                          <Box style={{ width: "100%" }}> <Typography
                            className={classes.reponame}
                            onClick={() => {
                              setSubvalue(index); setCurrentProjectId(value.id);
                              user.getBranchName(value.name_with_namespace);
                            }}
                          >
                            {value.name_with_namespace}
                          </Typography>
                            <Typography className={classes.Gtyposideupdaredvalue}>
                              Updated {timeAgo}
                            </Typography>
                          </Box>
                        </Box>
                        {subvalue == index && (
                          <Box className={classes.subreponame}>

                            {subvalue == index &&
                              value?.branches.map((data, key) => {
                                return (
                                  <>
                                    {
                                      <Box
                                        className={classes.subbranchbox}
                                        onClick={() => handleClick(data?.name)}
                                      >
                                        <img src={"/images/repoPath.svg"} alt="img" />
                                        <Typography
                                          className={
                                            selectedItem === data?.name ? `${classes.branchName} ${classes.highlighted}` : classes.branchName
                                          }
                                        >
                                          {data?.name}
                                        </Typography>
                                      </Box>
                                    }
                                  </>
                                );
                              })}
                          </Box>
                        )}
                      </Box>
                    </>
                  );
                })
              ) : (
                <Box className={classes.noprojectbox}>
                  <Typography varinat="body2" color="primary">No Project Added</Typography>
                </Box>
              )}

              {desiredRoutes?.includes(location?.pathname) && locationData?.state && locationData.state.map((value, index) => {
                return (
                  <>
                    <Box
                      className={classes.outerReponot}
                      style={{ display: "flex", alignItems: "center", gap: "15px" }}
                      onClick={() => handleTypeSelection(value?.path)}
                      {...rest}
                    >
                      <img
                        src="/images/vscodethird.svg"
                      /> <Box>
                        <Typography className={classes.pasthCss}>
                          {value?.path && value?.path.length > 10 ? value?.path.slice(0, 10) + "..." : value?.path || "Path"}
                        </Typography>
                        <Typography className={classes.language}>{value?.language}</Typography>
                      </Box>
                    </Box>
                  </>
                );
              })}

              {desiredRoutes.includes(location.pathname) && DataallRefact &&
                DataallRefact.map((value, index) => {
                  return (
                    <>
                      <Box
                        key={value.id}
                        className={classes.navboxrefa}
                        onClick={() => {
                          user.handleallsingledataview(value?.id)
                        }}
                      >
                        <img src="/images/vscodethird.svg" alt="Vs Code image" />
                        <Typography style={{ cursor: "pointer" }}
                        >
                          {value?.file_name}
                        </Typography>
                      </Box>
                    </>
                  );
                })
              }

              {location.pathname === "/allsave-content" ?
                <Box className={classes.rightleftbtn} >
                  <Button>
                    <BsCaretLeftFill color="#1756FF" fontSize={"20px"}
                      onClick={handlePreviousPage}
                    />
                  </Button>
                  <Typography>{user.page}</Typography>
                  <Button>
                    <BsCaretRightFill color="#1756FF" fontSize={"20px"}
                      onClick={handleNextPage}
                    />
                  </Button>
                </Box>
                :
                ""}
            </Box>
          }
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

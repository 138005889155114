import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "./layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/temporary",
    layout: HomeLayout,
    component: lazy(() => import("src/component/temporary")),
  },
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/LandingPage")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Signup")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Signup")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password-link/OtpVerify")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/ChangePassword/Index")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/role",
    layout: HomeLayout,
    component: lazy(() => import("src/views/auth/selectRole")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/connect-to-gitlab",
    guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/connectGitlab")),
  },
  {
    exact: true,
    path: "/change-gitlab-key",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/GitlabConnect/index")),
  },
  {
    exact: true,
    path: "/profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/index")),
  },
  {
    exact: true,
    path: "/code-refactor",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/refactorCode/refactorCode")),
  },
  {
    exact: true,
    path: "/break-editor",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Breakpoints")),
  },
  {
    exact: true,
    path: "/test-case",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/testCase/testCase")),
  },
  {
    exact: true,
    path: "/document",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/documentation/documentation")),
  },
  {
    exact: true,
    path: "/upload-file",
    guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/uploadFiles/upload")),
  },
  {
    exact: true,
    path: "/Viewopertaion",
    guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OperationList/ViewOperation")),
  },
  // {
  //   exact: true,
  //   path: "/allsave-content",
  //   guard: false,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/AllData/index")),
  // },
  {
    exact: true,
    path: "/privacy-policy",
    // guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/license/privacyPolicy")),
  },
  {
    exact: true,
    path: "/terms&condition",
    // guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/license/termsCondition")),
  },
  {
    exact: true,
    path: "/aboutUs",
    // guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/license/Aboutus")),
  },
  {
    exact: true,
    path: "/openAIkeys",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OpenAIKeys/openAiKeys")),
  },
  {
    exact: true,
    path: "/upload-file",
    guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/uploadFiles/upload")),
  },
  {
    exact: true,
    path: "/opertaion-List",
    guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OperationList/index")),
  },
  {
    exact: true,
    path: "/Viewopertaion",
    guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OperationList/ViewOperation")),
  },
  {
    exact: true,
    path: "/GitOperationView",
    guard: true,
     layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OperationList/GitConnectIndex")),
  },
  {
    exact: true,
    path: "/CodeRefactorView",
    guard: true,
     layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OperationList/GitConnectViewOperation")),
  },
  

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

//const url = "https://pypune-expertchatbot.mobiloitte.io";
//const url = "https://pytest-kyle.mobiloitte.io";
//const url = "http://172.16.6.204:8080"
const url = "https://v3py-expertchatbot.mobiloitte.io";
// const url = "http://172.16.6.43:8001/";

//const url = "http://172.16.6.43:8080";




const ApiConfig = {
  // auth
  userSignup: `${url}/userregistration`,
  login: `${url}/userlogin/`,
  registration: `${url}/userregistration`,
  sendOtp: `${url}/sendotp/`,
  verifyOTP: `${url}/verifyotp/`,
  changePassword: `${url}/changepassword/`,
  forgotPassword: `${url}/getforgotemail/`,
  resetPassword:`${url}/ResetPassword/`,
  // get profile
  viewuserprofile: `${url}/getuserprofile/`,
    // update profile
    updateprofile: `${url}/updateprofile/`,
  // connect to gitlab 
  connectToGitlab: `${url}/accesstoken/`,
  // get repo list
  getRepoList: `${url}/gitaccess/`,
  // get branch details
  getBranch: `${url}/branchwithrepo/`,
  // get file or folder details
  getFileFolderDetails: `${url}/filefolderaccess/`,
  // get refactored code
  getRefactoredCode: `${url}/multiplefilerefactor/`,
  // get test case
  getTestCase: `${url}/multifiletestcasegenerator/`,
  // get document
  getDocument: `${url}/multifiledocumentgenerator/`,
// Upload Code to gitlab
UploadCode: `${url}/push/`,
  // upload file for refactor , test case & document
  uploadFile: `${url}/uploadfile/`,
//Static Content
staticContent: `${url}/userstaticcontent/`,

// New Refactor code files
useroperationlist: `${url}/useroperationlist/`,
getRefactorCode: `${url}/getRefactorCode/`,
// getRefactorCode: `${url}/getRefactorCode/`,

//get Multiple File refator
multiplefileaccess: `${url}/multiplefileaccess/`,

// Save Code 
UploadFileSaveDetails: `${url}/UploadFileSaveDetails/`,
UploadFileSaveDetailsall: `${url}/UploadFileSaveDetailsall/`,
UploadFileDetailsSingleView: `${url}/UploadFileDetailsSingleView/`,
getALL_API_token:`${url}/getALL_API_token/`,
upload_API_token:`${url}/upload_API_token/`,
Update_API_token_ByName:`${url}/Update_API_token_ByName/`,
delete_API_token_ByID:`${url}/delete_API_token_ByID/`
};

export default ApiConfig;

import React, { useState, useEffect } from "react";
// import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SessionService from "./SessionService.js";
export default function SessionLogout({ children, location }) {
  const history = useHistory();
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [lastActiveTime, setLastActiveTime] = useState(new Date());
  const excludedPaths = [
    "/",
    "/login",
    "/signup",
    "/signup-otp",
    "/verify-otp",
    "/reset-password",
    "/changePassword",
    "/forget-password",
    "/privacy-policy",
    "/404",
    "/terms&condition",
    "/aboutUs",
  ];

  const currentPath = window.location.pathname;
  const isExcludedPath = excludedPaths.includes(currentPath);

  const handleOnIdle = (event) => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("creatturAccessToken");
    window.localStorage.clear();

    // Check if the current path is not in the excluded paths and the toast has not been displayed
    if (
      !excludedPaths.includes(window.location.pathname) &&
      !toastDisplayed
    ) {
      toast.error("Session has expired.");
      setToastDisplayed(true);
      history.push("/login");
    }
  };

  const handleOnActive = (event) => {
    // Reset the toastDisplayed state when the user becomes active
    setToastDisplayed(false);
  };

  const handleOnAction = (event) => { };

  const handleUserActivity = () => {
    setLastActiveTime(new Date());
    SessionService.clearTimers();
    SessionService.startTimers(handleSessionTimeout, handleIdleTimeout);
  };

  const handleSessionTimeout = () => {
    if (
      !SessionService.sessionExpired &&
      !SessionService.sessionToastDisplayed
    ) {

      SessionService.clearTimers();
      SessionService.setSessionExpired(true);
      SessionService.setSessionToastDisplayed(true);
      toast.error("Session has expired.");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("creatturAccessToken");
      // window.localStorage.clear();
      // window.localStorage.clear();

    }
  };

  const handleIdleTimeout = () => {
    if (!SessionService.idleExpired && !SessionService.idleToastDisplayed) {

      SessionService.clearTimers();
      SessionService.setIdleExpired(true);
      SessionService.setIdleToastDisplayed(true);
      toast.error("Session has expired due to inactivity.");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("creatturAccessToken");
      // window.localStorage.clear();
      // window.localStorage.clear();

    }
  };

  useEffect(() => {
    SessionService.startTimers(handleSessionTimeout, handleIdleTimeout);
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);
    return () => {
      SessionService.clearTimers();
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, []);

  // useEffect(() => {
  //   if (!isExcludedPath) {
  //     handleUserActivity();
  //   }
  // }, [isExcludedPath]);

  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: 1000 * 60 * 30, // 30 min.
  //   // timeout: 10000 , // 10 sec.
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  //   debounce: 500,
  // });
  useEffect(() => {
    if (!isExcludedPath) {
      handleUserActivity();
    }
  }, [isExcludedPath]);

  if (isExcludedPath) {
    return <div>{children}</div>;
  }
  return <div>{children}</div>;
}

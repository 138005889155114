import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";


// const useStyles = makeStyles((theme) => ({
//   content: {
//     // height: '100vh',
//     backgroundColor: "transparent",
//     // "&::-webkit-scrollbar": {
//     //   width: "0.4em",
//     // },
//     // "&::-webkit-scrollbar-track": {
//     //   margin: "40px 0 40px 0",
//     // },
//     // "&::-webkit-scrollbar-thumb": {
//     //   background:
//     //     "linear-gradient(180deg, rgba(47, 245, 255) 0%, rgba(2, 175, 184) 100%);",
//     // },
//     // "@media(max-width:600px)": {
//     //   padding: "40px 20px"
//     // },
//   },
//   left: {
//     height: "100%",
//     "@media(max-width:959px)": {
//       display: "none",
//     },

//   },

//   mainbox: {
//     height: "100%",
//     padding: "0rem",
//     paddingBottom: "0px",
//     marginTop: "25px",
//     paddingRight: "30px",
//     paddingLeft: "30px",
//   },
//   logoImage: {
//     position: "absolute",
//     zIndex: "1",
//     top: "20px",
//     maxWidth: "225px",
//     cursor: "pointer",
//     "@media(max-width:1279px)": {
//       display: "none",
//     },
//   },
//   mainScreen: {
//     alignItems: 'center',
//   },
//   mainScreenBack: {
//     maxWidth: "100%",
//   },
//   dontAccount: {
//     fontFamily: "Inter",
//     fontStyle: "normal",
//     fontWeight: "400",
//     fontSize: "18px",
//     lineHeight: "22px",
//     color: "#7239EA",
//     textAlign: "center",
//     cursor: 'pointer'
//   },
//   logo: {
//     cursor: "pointer",
//     width: "149px",

//     paddingLeft: "60px",
//   },
//   inputvalue: {
//     "&::placeholder": {
//       color: "#fff !important",
//       fontFamily: "Inter",
//       fontStyle: "normal",
//       fontWeight: "400",
//       fontSize: "12px",
//       lineHeight: "24px",
//     },
//     "& .MuiInputBase-input": {
//       color: "#D9D9D9 !important",
//       height: "24px",
//       padding: "0 78px 0px 0px",
//     },
//     "@media(max-width:1279px)": {
//       //width: "216%"
//     },
//     "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
//       borderBottom: 'none !important'
//     },
//   },
//   Background: {
//     backgroundColor: '#fff',
//     backgroundImage: 'url("/images/skull.png")',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center center',
//     backgroundSize: 'cover',
//     width: '100%',
//     height: '100vh',
//     "@media(max-width:959px)": {
//       display: 'none'
//     },
//   }
// }));


const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  logoContainer: {
    // position: "absolute",
    top: 35,
    left: 57,
    zIndex: 10,
  },
  loginLayoutBox: {
    background: "#fff",
    display: "flex",
    zIndex: "9",
    position: "relative",
    justifyContent: "center",
    height: "100vh",
    // alignItems: 'center',
    "& .loginLayoutBoxImg": {
      width: "50%",
      zIndex: "1",
      position: "fixed",
      backgroundSize: "cover",
      // background: "#ff860d",
      backgroundImage: "url(images/skull.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "100vh",
      right: 0,
      // [theme.breakpoints.down("sm")]: {
      //   display: "none",
      // },
      "@media(max-width:1000px)": {
              display: 'none'
            },
      "& h1": {
        fontSize: "65px",
      },
    },
  },
  loginContentLayoutBox: {
    background: "#fff",
    position: "absolute",
    left: 0,
    width: "50%",
    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    //   padding: "65px 0 50px",
    // },
    "@media(max-width:1000px)": {
      width: "100%",
      // padding: "65px 0 50px",
    },
    "@media(max-width:500px)": {
      width: "100%",
      // padding: "30px 0 40px",
    },
  },
  MainLayout: {
    minHeight: "calc(100vh - 72px)",
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));


const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("/dashboard");
    }
  }, []);
  const themeSeeting = React.useContext(SettingsContext);
  const inviteCode = "";
  const signupUrl =
    `${"/register"}?inviteCode=${inviteCode}`
  return (
    // <Box className={classes.mainScreenBack}>
    //   <Grid container className={classes.mainScreen}>
    //     <Grid item xs={12} sm={12} md={5} lg={5}>
    //       <Box className={classes.content}>{children}</Box>
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={7} lg={7} >
    //       <Box style={{ overflow: 'hidden' }}>
    //         <img className={classes.Background} src="/images/skull.png" alt="skull_img" />
    //       </Box>
    //     </Grid>
    //   </Grid>
    // </Box>
    <Box className={classes.loginLayoutBox}>
      <Box className={classes.loginContentLayoutBox}>
        <Box style={{ width: "100%" }}>{children}</Box>
      </Box>
      <Box className="loginLayoutBoxImg"></Box>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};



export default LoginLayout;


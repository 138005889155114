import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import ApiConfig from "../config/APIConfig";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Typography,
  DialogContent,
  TextField,
  Dialog,
  DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const UserContext = createContext();

export default function AuthProvider(props) {
  const location = useLocation();
  const history = useHistory();
  const [repoList, setRepoList] = useState([]);
  const [branchContent, setBranchContent] = useState([]);
  const [repoName, setRepoName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [permission, setPermission] = useState({});
  const [profile, setProfile] = useState({});

  // get projectDetails
  const getProfile = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.viewuserprofile,
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
      });
      if (res?.data?.status === 200) {
        setProfile(res?.data?.data);
        setPermission(res?.data?.permission);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        toast.error("You have been blocked by Admin");
        history.push("/login");
      }
      console.log(error);
    }
  };

  const locationPath = useLocation();

  // useEffect(() => {
  //   if (profile.blocked === false) {
  //   } else {
  //     if (location.pathname === "/login") {
  //     } else {
  //       toast.error("You Blocked by Admin");

  //       history.push("/login");
  //       localStorage.clear();
  //     }
  //   }
  // }, []);

  // get repolist or project list
  const getRepoList = async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getRepoList,
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
      });

      if (res.data.status === 200) {
        setLoading(false);
        setRepoList(res.data.projects);
      }
    } catch (error) {
      if (
        locationPath?.pathname === "/dashboard" &&
        error?.response?.data?.status === 401 &&
        error?.response?.data?.responseMessage ===
        "Invalid GitLab access token."
      ) {
        toast.error("Invalid GitLab access token", {
          onClose: () => {
            setLoading(false);
            history.push("/change-gitlab-key");
          },
        });
      } else {
        setLoading(false);
      }
    }
  };
  // get repolist or project list
  const [curentProjectId, setCurrentProjectId] = useState("");
  const [currentBranch, setCurrentBranch] = useState("");
  const [refactorLoader, setRefactorLoader] = useState(false);
  const [refactorCode, setRefactorCode] = useState([]);
  const [folderTree, setFolderTree] = useState([]);
  const [folderTreeData, setFolderTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [testCaseLoader, setTestCaseLoader] = useState(false);
  const [documentLoader, setDocumentLoader] = useState(false);
  const [upoladRefactorData, setUpoladRefactorData] = useState("");
  const [DataallRefact, setDataallRefact] = useState([]);
  const [uploadRefactor, setUploadRefactor] = useState([]);
  const [dataall, setDataAll] = useState([]);
  const [datacountrefact, setDataCountRefact] = useState([]);
  const [page, setPage] = useState("");

  const [newRefactor, setNewRefactor] = useState([]);
  const [newRefactorResponse, setNewRefactorResponse] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);


  useEffect(() => {
    if (
      sessionStorage.getItem("branchName") &&
      sessionStorage.getItem("projectId")
    ) {
      getBranchList(
        sessionStorage.getItem("projectId"),
        sessionStorage.getItem("branchName")
      );
      setCurrentProjectId(sessionStorage.getItem("projectId"));
      setCurrentBranch(sessionStorage.getItem("branchName"));
      // setBranchContent(sessionStorage.getItem("BranchContent"))
    }
  }, []);

  const getBranchList = async (projectId, branch) => {
    setCurrentProjectId(projectId);
    setCurrentBranch(branch);
    setLoading(false);
    try {
      setLoading(true);
      const res = await axios({
        method: "GET",
        url: ApiConfig.getBranch,
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
        params: {
          project_id: projectId,
          branch: branch,
        },
      });
      if (res.data.status === 200) {
        sessionStorage.setItem(
          "BranchContent",
          JSON.stringify(res?.data?.BranchContent)
        );
        setBranchContent(res.data.BranchContent);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get projectDetails
  const getFileFolderDetail = async (path, type) => {
    setLoading(false);
    try {
      setLoading(true);
      const res = await axios({
        method: "GET",
        url: ApiConfig.getFileFolderDetails,
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
        params: {
          project_id: curentProjectId,
          branch: currentBranch,
          path: path,
          type: type,
        },
      });
      if (res.data.status === 200) {
        setLoading(false);
        setFolderTree(res?.data?.content);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // Get Multiple Project Details

  const getMultipleFileFolderDetail = async (path, type) => {
    setLoading(false);
    try {
      setLoading(true);
      const res = await axios({
        method: "POST",
        url: ApiConfig.multiplefileaccess,
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
        params: {
          project_id: curentProjectId,
          branch: currentBranch,
          paths: path,
          type: type,
        },
      });
      if (res?.data?.status === 200) {
        setLoading(false);
        setFolderTreeData(res?.data?.content)
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleallsingledataview = async (uploadId) => {
    const token = window.localStorage.getItem("token");
    try {
      const res = await axios({
        method: "GET",
        url: `${ApiConfig.UploadFileDetailsSingleView}?id=${uploadId}`,
        headers: { Authorization: token },
      });
      if (res.data.status === 200) {
        toast.success(res.data.responseMessage);
        setDataAll(res?.data?.data);
        setLoading(false);
      } else {
        toast.error(res.data.responseMessage);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      setLoading(false);
    }
  };

  // get refactored code
  const getRefactoredCode = async (path, curentProjectId, currentBranch, breakpointsString) => {
    setRefactorLoader(true);
    try {
      const res = await axios({
        method: "POST",
        url: `${ApiConfig.getRefactoredCode}?project_id=${curentProjectId}&branch=${currentBranch}&paths=${path}`,
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
      });
      if (res.data.status === 200) {
        const data = res.data.FinalOutput;
        setNewRefactor(res.data.FinalOutput[0].file_name);
        setNewRefactorResponse(res.data.responseMessage);
        setDialogOpen(true);
        // history.push("/code-refactor", data);
        setRefactorLoader(false);
        setRefactorCode(res.data.FinalOutput);

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("type", data[0].path);
        const queryString = queryParams.toString();
        // const url = `/code-refactor?${queryString}`;
        const url = `/GitOperationView?${queryString}`;
        history.push(url, data);

        sessionStorage.setItem("branchName", currentBranch);
        sessionStorage.setItem("projectId", curentProjectId);
      }
    } catch (error) {
      setRefactorLoader(false);
      toast.error(error?.response?.data?.responseMessage);
      console.log(error);
    }
  };

  const getUploadRefacor = async (page) => {
    const token = window.localStorage.getItem("token");
    setUpoladRefactorData(true);
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.UploadFileSaveDetailsall,
        headers: { Authorization: token },
        params: {
          page: page,
        },
      });

      if (res.data.status === 200) {
        toast.success(res.data.responseMessage);
        setDataallRefact(res.data?.data);
        setDataCountRefact(res.data);
        setPage(res?.data?.current_page);
        setUpoladRefactorData(false);
      } else {
        toast.error(res.data.responseMessage);
        setUpoladRefactorData(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      setUpoladRefactorData(false);
    }
  };

  // get test case
  const getTestCase = async (path, breakpointsString) => {
    setTestCaseLoader(true);

    try {
      const res = await axios({
        method: "POST",
        url: `${ApiConfig.getTestCase}?project_id=${curentProjectId}&branch=${currentBranch}&paths=${path}`,
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
      });
      if (res.data.status === 200) {
        const data = res.data.TestCases;
        // history.push("/test-case", data);
        setTestCaseLoader(false);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("type", data[0].path);
        const queryString = queryParams.toString();
        // const url = `/test-case?${queryString}`;
        const url = `/GitOperationView?${queryString}`;
        history.push(url, data);
      }
    } catch (error) {
      setTestCaseLoader(false);
      toast.error(error?.response?.data?.responseMessage);
      console.log(error);
    }
  };
  // get document
  const getDocument = async (path, breakpointsString) => {
    setDocumentLoader(true);

    try {
      const res = await axios({
        method: "POST",
        url: `${ApiConfig.getDocument}?project_id=${curentProjectId}&branch=${currentBranch}&paths=${path}`,
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
      });

      if (res.data.status === 200) {
        const data = res.data.documents;
        // history.push("/document", data);
        setDocumentLoader(false);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("type", data[0].path);

        const queryString = queryParams.toString();
        // const url = `/document?${queryString}`;
        const url = `/GitOperationView?${queryString}`;
        history.push(url, data);
      }
    } catch (error) {
      setDocumentLoader(false);
      console.log("Error for 404");
      toast.error(error?.response?.data?.responseMessage);
      console.log(error);
    }
  };
  const getBranchName = async (path) => {
    setBranchName(path);
  };
  const getRepoName = async (path) => {
    setRepoName(path);
  };

  // Save the Code

  // change side bar function
  const [sideBarType, setSideBarType] = useState("dashboard");
  let data = {
    profile: profile,
    repoList: repoList,
    DataallRefact: DataallRefact,
    datacountrefact: datacountrefact,
    dataall: dataall,
    curentProjectId: curentProjectId,
    currentBranch: currentBranch,
    branchContent: branchContent,
    folderTree: folderTree,
    folderTreeData, folderTreeData,
    permission: permission,
    setUploadRefactor,
    loading,
    setLoading,
    page,
    refactorCode: refactorCode,
    refactorLoader: refactorLoader,
    testCaseLoader: testCaseLoader,
    documentLoader: documentLoader,
    branchName: branchName,
    repoName: repoName,
    getBranchName: (branchname) => {
      getBranchName(branchname);
    },
    getRepoName: (reponame) => {
      getRepoName(reponame);
    },
    getProfile: () => {
      getProfile();
    },
    getUploadRefacor: (page) => {
      getUploadRefacor(page);
    },
    getBranchList: (projectId, branch) => {
      getBranchList(projectId, branch);
    },
    getRepoList: () => {
      getRepoList();
    },
    getFileFolderDetail: (path, type) => {
      getFileFolderDetail(path, type);
    },
    getMultipleFileFolderDetail: (path, type) => {
      getMultipleFileFolderDetail(path, type);
    },
    handleallsingledataview: (uploadId) => {
      handleallsingledataview(uploadId);
    },
    getRefactoredCode: (path) => {
      getRefactoredCode(path, curentProjectId, currentBranch);
    },
    getTestCase: (path) => {
      getTestCase(path);
    },
    getDocument: (path) => {
      getDocument(path);
    },

    // handleSidebar:(type) => {handleSidebar(type)}
  };

  function ApiProcessingDialog({ open, newRefactorResponse, newRefactor }) {
    return (
      <Dialog
        open={open}
        onClose={() => setDialogOpen(false)}
      >
        <DialogContent style={{ width: "500px" }}>
          <form>
            {/* <Box>
              <Typography>File ID</Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                value={data.file_id}
                disabled
                // onChange={handleInputChange(field)}
              />
            </Box> */}
            <Box>
              <Typography>File Name</Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                value={newRefactor}
                disabled
              // onChange={handleInputChange(field)}
              />
            </Box>
            <Box>
              <Typography>Response Message</Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                value={newRefactorResponse}
                disabled
              // onChange={handleInputChange(field)}
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Box fullWidth>
            <Button
              variant="outlined"
              color="primary"

              onClick={() => {
                history.push("/GitOperationView");
                setDialogOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
      {dialogOpen && <ApiProcessingDialog open={dialogOpen} newRefactorResponse={newRefactorResponse} newRefactor={newRefactor} />}
    </>
  );
}
